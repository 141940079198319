import { Journal, getJournalEntry } from "./journal"

export type Virtue = {
  readonly name: string
  readonly description: string
  readonly acronym: string
}

export function virtueHasDot(
  journal: Journal,
  virtue: Virtue,
  date: Date,
): boolean {
  const entry = getJournalEntry(journal, date)
  if (entry == null) return false
  return entry.virtues.find((name) => name == virtue.name) != null
}
