import { Journal, journalDateString } from "./modules/journal"
import { Virtue, virtueHasDot } from "./modules/virtue"
import { assertIsDefined, weekDays } from "./modules/helpers"
import {
  cacheJournal,
  cacheMarkedVirtues,
  cacheUserEmail,
  getCachedJournal,
  getCachedUserEmail,
  retrieveJournal,
} from "./modules/database"
import {
  getCurrentUserEmail,
  startAuthenticationListener,
} from "./modules/auth"
import { getVirtueOfTheWeek, getVirtues } from "./modules/VirtueSchema"
import { initialiseFirebaseApp } from "./modules/firebase"

const app = initialiseFirebaseApp()
startAuthenticationListener(app)

const BORDER_STYLE_CLASSES: string[] = ["border", "border-slate-300"]
// This does not yet support showing virtues of multiple virtue schema's in the same week. See also this issue: https://github.com/TomGijselinck/virtues-journal/issues/22.
const renderJournal = (journal: Journal, userEmail: string) => {
  //Render table header
  const virtueOfTheWeek = getVirtueOfTheWeek(new Date(), userEmail)
  const tableHeaderTitle = document.getElementById("tableHeaderTitle")
  assertIsDefined(tableHeaderTitle)
  tableHeaderTitle.innerText = virtueOfTheWeek.name
  const tableHeaderDescription = document.getElementById(
    "tableHeaderDescription",
  )
  assertIsDefined(tableHeaderDescription)
  tableHeaderDescription.innerText = virtueOfTheWeek.description

  // Render table body
  const tableBody = document.getElementById("tableBody")
  assertIsDefined(tableBody)
  tableBody.innerHTML = ""
  for (const virtue of getVirtues(userEmail)) {
    const row = virtueRow(virtue, journal)
    tableBody.appendChild(row)
  }

  // Cache data
  const todayDateString = journalDateString(new Date())
  const todayEntry = journal.get(todayDateString)
  const markedVirtuesOfToday = todayEntry != null ? todayEntry.virtues : []
  cacheMarkedVirtues(markedVirtuesOfToday)
  cacheJournal(journal)
  cacheUserEmail(userEmail)
}
const onError = () => {
  console.warn("failed to retrieve journal")
}
const cachedJournal = getCachedJournal()
const render = (journal: Journal) => {
  let userEmail = getCurrentUserEmail()
  userEmail = userEmail == null ? getCachedUserEmail() : userEmail
  renderJournal(journal, userEmail)
}
render(cachedJournal)
// Note that by this time the user will also be set by firebase, otherwise retrieve journal would error
retrieveJournal(app).then(render).catch(onError)

function virtueRow(virtue: Virtue, journal: Journal): HTMLElement {
  const row = document.createElement("tr")
  const header = virtueHeaderCell(virtue)
  row.appendChild(header)
  const today = new Date()
  const days = weekDays(today)
  for (const day of days) {
    const cell = virtueCell(virtue, journal, day)
    row.appendChild(cell)
  }
  return row
}

function virtueCell(virtue: Virtue, journal: Journal, date: Date): HTMLElement {
  const cell = document.createElement("td")
  cell.classList.add(...BORDER_STYLE_CLASSES)
  const dotted = virtueHasDot(journal, virtue, date)
  if (dotted) {
    cell.innerHTML = `
        <svg class="p-2.5" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50"/>
        </svg>
        `
  }
  return cell
}

function virtueHeaderCell(virtue: Virtue): HTMLElement {
  const virtueHeaderCell = document.createElement("td")
  virtueHeaderCell.classList.add(...BORDER_STYLE_CLASSES, "p-2", "px-12")
  virtueHeaderCell.innerText = virtue.acronym
  return virtueHeaderCell
}
